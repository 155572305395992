define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["cs", {
    "21%": "21%",
    "Add": "Přidat",
    "Bank transfer": "Bankovním převodem",
    "Cancel": "Zrušit",
    "Close": "Zavřít",
    "Confirmation": "Potvrzení",
    "DataGrid": {
      "fulltextLabel": "Filtrace",
      "fulltextPlaceholder": "Hledaná fráze"
    },
    "Delete": "Smazat",
    "Deleted": "Smazáno",
    "Edit": "Upravit",
    "History": "Historie",
    "ID": "ID",
    "Identification": "Uživatelské jméno",
    "Login": "Přihlásit",
    "My secret password": "Moje tajné heslo",
    "New": "Nový",
    "Next": "Další",
    "No data": "Žádná data",
    "Password": "Heslo",
    "Previous": "Předchozí",
    "Save": "Uložit",
    "Saved": "Uloženo",
    "Saving": "Ukládám...",
    "Select manually": "Vybrat ručně",
    "Submit": "Odeslat",
    "Sum": "Suma",
    "T": "T",
    "Today": "Dnes",
    "admin": {
      "companies": {
        "address": "Adresa",
        "companyHeading": "Moje firma",
        "entitiesHeading": "Ekonomické entity",
        "expiryDate": "Zaplaceno do",
        "facebook": "Facebook",
        "isVatPayer": "Plátce DPH",
        "messageLength": "Délka zprávy",
        "phone": "Telefon",
        "save": "Uložit",
        "website": "Web"
      },
      "customer": {
        "visitsSum": "Celkem"
      },
      "customers": {
        "all": "Všichni zákazníci",
        "import": "Import"
      },
      "employee": {
        "back": "Seznam zaměstnanců",
        "dispensesAndSells": "Výdeje / Prodeje",
        "edit": "Editace",
        "heading": "Zaměstnanec {name}",
        "items": {
          "count": "Prodáno kusů",
          "itemName": "Položka",
          "itemSellingPrice": "Jednotková cena",
          "sum": "Suma",
          "sumOfPurchasePrices": "Suma v poslední nákupní ceně",
          "sumOfPurchasePricesVAT": "Suma v poslední nákupní ceně s DPH",
          "sumOfSellingPricesVAT": "Suma v prodejní ceně s DPH"
        },
        "itemsList": "Položky skladu",
        "month": "Měsíc",
        "notActive": "Neaktivní",
        "percent": "%",
        "save": "Uložit",
        "savedError": "Při ukládání došlo k chybě",
        "savedOk": "Zaměstnanec uložen",
        "visits": "Zakázky",
        "visitsEmployeeShareTitle": "{shareOfSales}*{amountSales} + {shareOfServices}*{amountServices}"
      },
      "import": {
        "customer": {
          "welcomeMessage": "Zde můžete provést hromadný import zákazníků"
        },
        "dataFormat": "Formát dat",
        "done": "Importováno {count} řádků",
        "error": "Chyba importu",
        "heading": {
          "customer": "Zákazníci",
          "item": "Položky skladu",
          "page": "Import"
        },
        "item": {
          "csv": "Vstupní CSV",
          "example": "Inoa 3.14,5671237890,12.34 Kč\nDětský střih,01001010101,501,90\nŠampon,55555555555,69 CZK",
          "exampleHeading": "Příklad CSV",
          "parse": "Parsovat",
          "save": "Uložit"
        },
        "message": "Formát vstupního CSV",
        "model": {
          "customer": "Celé jméno, Jméno, Příjmení, , , , , , , , , , , Datum narození, Pohlaví, , , , , , , , , , Poznámka, , [Email], Email, , , , , , [Mobile], Mobilní telefon",
          "item": ", , , , , , , , , , Název položky, EAN (čárový kód), Prodejní cena, Výdejní cena, Minimálně skladem",
          "itemBulk": "Čárový kód, Název položky, Výdejní cena, Prodejní cena",
          "supply": ", Množství, , Nákupní cena (bez DPH), , Daň v (%), , , , , , EAN (čárový kód)"
        },
        "reset": "Začít znovu",
        "sampleData": {
          "item": "Šampón, 9004715248468, 120, 100, 10",
          "supply": "9004715248468, 10, 120, 21"
        },
        "supply": "Zde můžete provést hromadné naskladnění"
      },
      "item": {
        "back": "Seznam položek skladu",
        "edit": "Editace",
        "heading": "Položka skladu {name}",
        "mailtoBody": "Děkujeme za nákup {name}",
        "mailtoLabel": "Poslat e-mail všem kupujícím",
        "mailtoSubject": "Newsletter pro {name}",
        "savedError": "Položku se nepodařilo uložit",
        "savedOk": "Položka uložena",
        "sells": "Prodeje",
        "supplies": "Naskladnění"
      },
      "items": {
        "activeAndInactive": "Obojí",
        "barcode": "Čárový kód",
        "bulk": "Hromadné",
        "bulkWelcomeMessage": "Hromadné změny položek skladu se provádějí zde",
        "entityName": "Ekonomická entita",
        "export": "Exportovat",
        "import": "Import",
        "isActiveLabel": "Zobrazit pouze",
        "isNotService": "Prodej",
        "isService": "Služba",
        "isServiceAndNotService": "Obojí",
        "name": "Název",
        "onlyActive": "Aktivní",
        "onlyInactive": "Neaktivní",
        "perPage": "Zobrazit položek na stránku",
        "purchasePrice": "Poslední nákupní cena",
        "sellingPrice": "Prodejní cena",
        "stock": "Skladem",
        "stockExportFileName": "sklad.xlsx",
        "stockExportSheetName": "Položky skladu",
        "sum": "Suma skladu",
        "sumExplained": "Počítají se pouze vyfiltrované položky, které nemají neomezené množství a mají poslední nákupní cenu > 0",
        "sumFiltered": "Vyfiltrováno"
      },
      "settlement": {
        "add": "Přidat položku",
        "footer": "Vytvořeno v",
        "header": "Faktura - daňový doklad {invoiceNumber}",
        "invalidBban": "Zadané číslo účtu musí být ve formátu xxxxxx-xxxxxxxxxx/xxxx aby mohl být vygenerován QR kód pro platbu",
        "payment": "Platba",
        "subscriber": "Odběratel",
        "supplier": "Dodavatel"
      },
      "supply": {
        "dragAndDrop": "Přetažením DOD souboru můžete provést hromadný import.",
        "dropToUpload": "Uvolněte zde.",
        "export": "Exportovat",
        "history": "Historie",
        "import": "Import",
        "importFormat": "čárový kód, množství, nákupní cena (bez DPH), DPH nákupu",
        "importInfo": "Podporovaný formát je CSV (čárkami oddělené hodnoty). Jednotlivé sloupečky jsou:",
        "lastSupplies": "Posledních {count} naskladnění",
        "new": "Manuální naskladnění",
        "newSupply": "Nové naskladnění",
        "uploadProgress": "Soubor načten, pokračujte dále."
      },
      "users": {
        "email": "E-mail"
      },
      "visit": {
        "createdAt": "Datum",
        "customer": "Zákazník",
        "delete": "Smazat",
        "employee": "Zaměstnanec",
        "header": "Zakázka",
        "note": "Poznámka",
        "reopenFailed": "Otevření selhalo",
        "reopenWarning": "Pozor! Již odeslané hlášení na EET se nebudou znovu odesílat. Všechny ostatní změny které provedete budou mít vliv napříč celým systémem (provize zaměstnanců, přehled tržeb, pokladní kniha, stav skladu).",
        "reopened": "Zakázka znovu otevřena",
        "revenue": {
          "amount": "Celkem",
          "amountSales": "Prodeje",
          "amountServices": "Služby",
          "entityName": "Ekonomická entita"
        },
        "revenues": "Příjmy",
        "saveFailed": "Uložení selhalo",
        "saved": "Uloženo",
        "sells": "Položky",
        "view": "Zobrazit",
        "visitDetails": "Detaily"
      },
      "wages": {
        "companyDebt": "Zbývá",
        "incomes": "Příjmy",
        "left": "Zbývá",
        "new": "Nová výplata",
        "shareSales": "Podíly na prodejích",
        "shareServices": "Podíly na službách",
        "sums": "Celkové součty",
        "wages": "Výplaty"
      }
    },
    "api": {
      "200": "Uloženo",
      "500": "Nastala chyba: {message}"
    },
    "app-menu": {
      "top": {
        "logoAlt": "beauty-pay.cz logo"
      }
    },
    "application": {
      "title": "kasa"
    },
    "beauty-pay.cz": "beauty-pay.cz",
    "calendar": {
      "allEmployees": "Všichni zaměstnanci",
      "clipboard": "Schránka",
      "copySchedules": "Kopírovat směny",
      "day": "Dne",
      "editMode": "Editovat směny",
      "end": "Konec",
      "from": "od",
      "header": "Rezervace",
      "modeReservation": "ne",
      "modeSchedule": "ano",
      "pasteSchedules": "Vložit směny",
      "reservationActions": "Akce",
      "start": "Začátek",
      "to": "do",
      "weeklySchedules": "Zobrazený týden"
    },
    "cashbook": {
      "add": "Přidat",
      "amount": "Částka",
      "createdAt": "Datum",
      "disclaimer": "Výběr z pokladní knihy dělejte přes zápornou částku.",
      "heading": "Pokladní kniha",
      "isVisit": "Zakázka",
      "kind": "Typ / Akce",
      "note": "Poznámka"
    },
    "createdAt": "Vytvořeno",
    "currency": "Kč",
    "customer": {
      "action": "Akce",
      "add": "Přidat zákazníka",
      "allCustomers": "Seznam zákazníků",
      "all_visits": "Seznam návštěv",
      "birth": "Datum narození",
      "birthdayIn": "Narozeniny",
      "export": "Exportovat kontakty",
      "firstName": "Jméno",
      "fullName": "Jméno",
      "gender": "Pohlaví",
      "genders": {
        "female": "Žena",
        "male": "Muž"
      },
      "isApproved": "Schválen",
      "lastName": "Příjmení",
      "lastVisitDate": "Poslední návštěva",
      "mail": "E-mail",
      "note": "Poznámka",
      "phone": "Telefon",
      "saveErrro": "Chyba při uložení",
      "saveOk": "Uloženo",
      "visitsCount": {
        "label": "{count} {count, plural, one {zakázka} few {zakázky} other {zakázek}}"
      },
      "visits_length": "Návštěv"
    },
    "customers": {
      "all": "Všichni zákazníci",
      "filterBy": {
        "label": "Filtr",
        "placeholder": "Jan Novák..."
      },
      "newCustomer": "Nový zákazník",
      "newsletter": "Newsletter"
    },
    "dateSelect": {
      "date": "den",
      "enabled": "Poskytnout",
      "month": "měsíc",
      "year": "rok"
    },
    "employee": {
      "action": "Akce",
      "add": "Přidat",
      "all_employees": "Všichni zaměstnanci",
      "all_visits": "Všechny zakázky",
      "color": "Barva",
      "firstName": "Jméno",
      "isActive": "Aktivní",
      "lastName": "Příjmení",
      "photoUrl": "Fotografie"
    },
    "employees": {
      "all": "Zaměstnanci"
    },
    "export": {
      "itemDispenses": {
        "sheet": {
          "all": "Všechny výdeje",
          "sums": "Sumy"
        }
      }
    },
    "failed": "selhalo",
    "female": "Žena",
    "form": {
      "save": "Uložit"
    },
    "formFor": {
      "amount": "Částka",
      "cashbook-entry": {
        "amount": "Částka",
        "note": "Poznámka"
      },
      "company": {
        "address": "Adresa",
        "email": "E-mail",
        "facebook": "Facebook",
        "invoice-header": "Hlavička na účtence",
        "invoice-logo": "Logo na účtence",
        "is-invoice-printing-active": "Tisknout účtenky",
        "name": "Název firmy",
        "notificationPlaceholdersHelp": "Delší zprávy jak 170 znaků budou rozděleny na více SMS. Zástupné symboly: %'{company_name}' - jméno firmy, %'{date}' - datum návštěvy, %'{time}' čas návštěvy, %'{company_phone}' - telefon firmy",
        "notificationWarning": "Před zapnutím nejdříve prosím kontaktujte podporu beauty-pay.cz",
        "phone": "Telefon",
        "reservation-notification-template": "Šablona SMS zprávy",
        "save": "Uložit",
        "website": "Web",
        "will-notify-reservations": "SMS upozornění zákazníků na nadcházející rezervace"
      },
      "customer": {
        "agree": "Souhlasím s",
        "birth": "Datum narození",
        "first-name": "Jméno",
        "gender": "Pohlaví",
        "is-approved": "Schválen",
        "last-name": "Příjmení",
        "mail": "E-mail",
        "note": "Poznámka",
        "phone": "Telefon",
        "terms-and-conditions": "Podmínky zpracování osobních údajů"
      },
      "employee": {
        "color": "Barva",
        "first-name": "Jméno",
        "is-active": "Aktivní",
        "last-name": "Příjmení",
        "photo-url": "URL fotky",
        "share-of-sales": "Podíl z prodejů",
        "share-of-sales-percent": "Podíl z prodejů",
        "share-of-services": "Podíl ze služeb",
        "share-of-services-percent": "Podíl ze služeb"
      },
      "entity": {
        "business-register": "Zápis v obchodním rejstříku",
        "headquarters": "Sídlo společnosti",
        "identification-number": "IČO",
        "invoice-header": "Patička na účtence",
        "is-vat-payer": "Plátce DPH",
        "name": "Název entity",
        "premisesid": "Číslo provozovny",
        "registerid": "Číslo pokladny",
        "send-eet": "Odesílat EET - pouze prodeje",
        "vatid": "DIČ"
      },
      "identification": "E-mail",
      "item": {
        "barcode": "Čárový kód",
        "entity": "Ekonomická entita",
        "is-active": "Aktivní",
        "is-service": "Služba",
        "name": "Název",
        "selling-price": "Prodejní cena",
        "unlimited": "Neomezeně",
        "warning-threshold": "Minimálně skladem"
      },
      "note": "Poznámka",
      "password": "Heslo",
      "reservation": {
        "end": "do",
        "note": "Poznámka",
        "start": "od",
        "will-notify": "Upozornit zákazníka dopředu SMS zprávou"
      },
      "schedule": {
        "note": "Poznámka"
      },
      "sell": {
        "count": "Kusů",
        "discount": "Sleva",
        "price": "Cena"
      },
      "supply": {
        "item": {
          "selling-price": "Prodejní cena"
        },
        "itemName": "Položka skladu",
        "purchase-price": "Nákupní cena (bez DPH)",
        "quantity": "Množství",
        "vat": "DPH nákupu"
      },
      "user": {
        "password": "Heslo",
        "passwordConfirmation": "Potvrzení hesla"
      },
      "username": "E-mail"
    },
    "formSelect": {
      "searching": "Vyhledávám ..."
    },
    "inPercent": "{name} v (%)",
    "intervalPicker": {
      "export": "Stáhnout",
      "from": "od",
      "invalidRange": "Datum \"od\" nesmí být po datumu \"do\".",
      "minusMonth": "-1 měsíc",
      "plusMonth": "+1 měsíc",
      "thisDay": "Dnes",
      "thisMonth": "Tento měsíc",
      "thisWeek": "Tento týden",
      "to": "do"
    },
    "item": {
      "action": "Akce",
      "actualCount": "Skladem",
      "barcode": "Čárový kód",
      "bought": "Naskladněno",
      "edit": "Upravit",
      "isActive": "Aktivní",
      "isService": "Služba",
      "margin": "Marže",
      "name": "Název",
      "new": "Nová položka skladu",
      "newItemEntity": "Ekonomický subjekt",
      "purchasePrice": "Nákup",
      "sellingPrice": "Prodejní cena",
      "sold": "Prodáno",
      "stock": "Skladem",
      "unlimited": "Neomezené množství",
      "unlimited_or_stock": "Skladem",
      "warningThreshold": "Minimálně skladem"
    },
    "itemDispenses": {
      "historyOfItemDispenses": "Historie výdejů",
      "new": "Nový výdej",
      "sells": "Historie prodejů"
    },
    "items": {
      "active": "Aktivní",
      "add": "Přidat",
      "all": "Sklad",
      "filterBy": {
        "count": "kusů",
        "label": "Filtrace",
        "placeholder": "Šampón...",
        "showing": "Zobrazeno"
      },
      "inactive": "Neaktivní",
      "item": {
        "lastDispenses": "Posledních 10 vydání",
        "newItemDispense": "Nové vydání"
      },
      "newBarcode": "123456789",
      "newName": "Nová položka",
      "newSellingPrice": "100",
      "newStock": "10",
      "newWarningThreshold": "5"
    },
    "john@doe": {
      "cz": "jan@novak.cz"
    },
    "loadMore": "Více...",
    "loading": "... načítám ...",
    "login": {
      "branding": "beauty-pay.cz",
      "header": "Přihlášení",
      "invalidCredentials": "Neplatné přihlašovací údaje",
      "invalidEmailOrPassword": "Neplatné jméno nebo heslo",
      "logoAlt": "beauty-pay.cz logo",
      "signup": "Registrovat",
      "somethingWentWrong": "Něco se pokazilo: {errorCode}",
      "submit": "Přihlásit",
      "youNeedToSignIn": "Nejprve se musíte přihlásit"
    },
    "male": "Muž",
    "min": "min",
    "minutes": "{count, plural, =1 {{count} minuta} =2 {{count} minuty} other {{count} minut}}",
    "models": {
      "cashbookEntry": {
        "amount": "Částka",
        "isVisit": "Zakázka",
        "kind": "Typ",
        "note": "Poznámka",
        "touchedAt": "Datum"
      },
      "company": {
        "_modelName": "{count, plural, =1 {Firma} =2 {Firmy} other {Firem}}",
        "address": "Adresa",
        "email": "E-mail",
        "expiryDate": "Datum vypršení",
        "facebook": "Facebook URL",
        "facebookTitle": "Jméno na Facebooku",
        "hasGdprEmails": "Odesílat GDPR e-maily při registraci",
        "instagram": "Instagram URL",
        "instagramTitle": "Jméno na Instagramu",
        "invoiceHeader": "Hlavička na účtence",
        "invoiceLogo": "Logo",
        "isInvoicePrintingActive": "Tisknout účtenku",
        "name": "Název",
        "note": "Poznámka",
        "phone": "Telefon",
        "phoneLandline": "Pevná linka",
        "reservationNotificationTemplate": "Šablona upozornění",
        "website": "Web",
        "willNotifyReservations": "Odesílat upozornění o rezervacích"
      },
      "customer": {
        "_modelName": "{count, plural, =1 {Zákazník} =2 {Zákazníci} other {Zákazníků}}",
        "birth": "Datum narození",
        "firstName": "Jméno",
        "fullName": "Jméno",
        "gender": "Pohlaví",
        "genders": {
          "female": "Žena",
          "male": "Muž"
        },
        "isApproved": "Schválený",
        "lastName": "Příjmení",
        "lastVisitDate": "Poslední návštěva",
        "mail": "E-mail",
        "name": "Jméno",
        "note": "Poznámka",
        "phone": "Telefonní číslo",
        "termsConfirmation": "Souhlasím s podmínkami zpracování osobních údajů",
        "visitsCount": "Návštěv"
      },
      "employee": {
        "_modelName": "{count, plural, =1 {Zaměstnanec} =2 {Zaměstnanci} other {Zaměstnanců}}",
        "companyDebt": "Dluh firmy",
        "contactDetails": "Kontaktní údaje",
        "firstName": "Jméno",
        "fullName": "Jméno",
        "lastName": "Příjmení",
        "photoUrl": "Fotografie",
        "shareOfSales": "Podíl z prodejů",
        "shareOfServices": "Podíl ze služeb",
        "sumOfShareServices": "Suma provizí ze služeb",
        "sumOfTips": "Suma dýšek",
        "sumOfWages": "Suma výplat"
      },
      "entity": {
        "_modelName": "{count, plural, =1 {Ekonomická entita} =2 {Ekonomické entity} other {Ekonomických entit}}",
        "accountNumber": "Číslo účtu",
        "businessRegister": "Zápis v obchodním rejstříku",
        "headquarters": "Sídlo společnosti",
        "identificationNumber": "IČO",
        "invoiceHeader": "Patička na účtence",
        "isVatPayer": "Plátce DPH",
        "name": "Název entity",
        "premisesid": "Číslo provozovny",
        "registerid": "Číslo pokladny",
        "sendEet": "Odesílat EET - pouze prodeje",
        "settlementLogo": "Logo na faktuře",
        "vatid": "DIČ",
        "weight": "Váha"
      },
      "invoice": {
        "_modelName": "{count, plural, =1 {Faktura} =2 {Faktury} other {Faktur}}",
        "amount": "Částka",
        "end": "Do",
        "isPaid": "Zaplaceno",
        "note": "Poznámka",
        "sentSms": "SMS",
        "start": "Od"
      },
      "item": {
        "_modelName": "{count, plural, =1 {Položka skladu} =2 {Položky skladu} other {Položek skladu}}",
        "barcode": "Čárový kód",
        "bought": "Naskladněno",
        "dispensed": "Vydáno",
        "dispensingPrice": "Výdejní cena",
        "duration": "Trvání",
        "isActive": "Aktivní",
        "isService": "Služba",
        "lastPurchasePrice": "Poslední nákupní cena",
        "margin": "Marže",
        "name": "Název",
        "remainsBeforeWarning": "Minimálně skladem",
        "sellingPrice": "Prodejní cena",
        "sold": "Prodáno",
        "stock": "Skladem",
        "stockUpdatedAt": "Poslední změna množství",
        "unlimited": "Neomezené množství",
        "warningThreshold": "Minimálně skladem"
      },
      "itemDispense": {
        "_modelName": "{count, plural, =1 {Výdej} =2 {Výdeje} other {Výdejů}}",
        "count": "Kusů",
        "sumPrice": "Cena",
        "unitPrice": "Cena / ks"
      },
      "language": {
        "_modelName": "{count, plural, =1 {Jazyk} =2 {Jazyky} other {Jazyků}}"
      },
      "notification": {
        "_modelName": "Notifikace",
        "message": "Text",
        "notification": "{count, plural, =1 {Notifikace} =2 {Notifikace} other {Notifikací}}",
        "phone": "Telefon",
        "sendAt": "Odesláno",
        "sentSms": "Zpráv",
        "status": "Stav",
        "statusMessage": "Status"
      },
      "payment": {
        "_modelName": "{count, plural, =1 {Platba} =2 {Platby} other {Plateb}}",
        "amount": "Částka",
        "end": "Do",
        "note": "Poznámka",
        "start": "Od"
      },
      "reservation": {
        "_modelName": "{count, plural, other {Rezervace}}",
        "end": "Konec",
        "note": "Poznámka",
        "start": "Začátek",
        "willNotify": "Upozornit"
      },
      "role": {
        "_modelName": "{count, plural, =1 {Role} =2 {Role} other {Rolí}}",
        "description": "Popisek",
        "featureFlags": "Práva",
        "name": "Název"
      },
      "sell": {
        "_modelName": "{count, plural, =1 {Prodej} =2 {Prodeje} other {Prodejů}}",
        "count": "Kusů",
        "discount": "Sleva",
        "item": "Položka",
        "price": "Jed. cena",
        "sum": "Suma"
      },
      "settlement": {
        "_modelName": "{count, plural, =1 {Faktura} =2 {Faktury} other {Faktur}}",
        "accountNumber": "Číslo účtu",
        "contactDetails": "Kontaktní údaje",
        "end": "Do",
        "entityName": "Dodavatel",
        "headquarters": "Sídlo společnosti",
        "identificationNumber": "IČO",
        "invoiceNumber": "Variabilní symbol",
        "issuedAt": "Datum vystavení",
        "methodOfPayment": "Způsob úhrady",
        "note": "Poznámka",
        "payUntil": "Datum splatnosti",
        "settlementLogo": "Logo",
        "start": "Od",
        "sum": "Celkem k platbě s DPH",
        "sumWithoutVat": "Základ daně",
        "taxablePerformance": "Datum zdanitelného plnění",
        "vat": "Sazba DPH",
        "vatAsNumber": "Daň",
        "vatid": "DIČ"
      },
      "settlementItem": {
        "_modelName": "{count, plural, =1 {Položka} =2 {Položky} other {Položek}}",
        "amount": "Množství",
        "name": "Popis",
        "sum": "Celkem s DPH",
        "sumWithoutVat": "Základ daně",
        "unitPriceWithoutVat": "Jednotková cena",
        "vat": "DPH"
      },
      "signup": {
        "_modelName": "{count, plural, =1 {Registrace} =2 {Registrace} other {Registrací}}",
        "discountCode": "Slevový kód",
        "email": "E-mail",
        "employeeCount": "Počet zaměstnanců / kolegů",
        "firstName": "Jméno",
        "identificationNumber": "IČO",
        "lastName": "Příjmení",
        "phone": "Telefon"
      },
      "supply": {
        "_modelName": "{count, plural, =1 {Naskladnění} =2 {Naskladnění} other {Naskladnění}}",
        "createdAt": "Vytvořeno",
        "margin": "Marže",
        "purchasePrice": "Nákupní cena (bez DPH)",
        "quantity": "Množství",
        "vat": "DPH nákupu"
      },
      "user": {
        "_modelName": "{count, plural, =1 {Uživatel} =2 {Uživatelé} other {Uživatelů}}",
        "authenticationToken": "Token",
        "currentSignInAt": "Momentální přihlášení",
        "email": "E-mail",
        "lastSignInAt": "Poslední přihlášení",
        "password": "Heslo",
        "passwordConfirmation": "Potvrzení hesla",
        "signInCount": "Počet přihlášení"
      },
      "vat": {
        "0": "Nulová (0%)",
        "10": "Druhá snížená (10%)",
        "21": "Základní (21%)",
        "_modelName": "DPH"
      },
      "visit": {
        "_modelName": "{count, plural, =1 {Návštěva} =2 {Návštěvy} other {Návštěv}}",
        "amountSales": "Prodeje",
        "amountServices": "Služby",
        "completed": "Uzavřeno",
        "employeeShare": "➗ zaměstnance",
        "employeeShareSales": "➗ z prodeje",
        "employeeShareServices": "➗ za služby",
        "note": "Poznámka",
        "paidByCard": "Zaplaceno kartou",
        "paidByOther": "Zaplaceno jinak",
        "paidInCash": "Zaplaceno v hotovosti",
        "paidInVoucher": "Zaplaceno vouchery",
        "price": "Cena",
        "priceWithTip": "Celková částka i s dýškem",
        "tip": "Dýško"
      },
      "voucher": {
        "_modelName": "{count, plural, =1 {Voucher} =2 {Vouchery} other {Voucherů}}",
        "barcode": "Čárový kód",
        "currentValue": "Zůstatek",
        "name": "Název",
        "note": "Poznámka",
        "sellingPrice": "Prodejní cena",
        "validUntil": "Platnost do"
      },
      "voucherClaim": {
        "_modelName": "{count, plural, =1 {Uplatnění vouchery} =2 {Uplatnění voucherů} other {Uplatnění voucherů}}",
        "amount": "Částka",
        "createdAt": "Vytvořeno"
      },
      "wage": {
        "_modelName": "{count, plural, =1 {Výplata} =2 {Výplaty} other {Výplat}}",
        "amount": "Částka",
        "note": "Poznámka"
      }
    },
    "noNewNotifications": "Žádné nové notifikace",
    "not_needed": "nebylo třeba",
    "notification": {
      "message": "Zpráva",
      "phone": "Telefon",
      "sendAt": "Čas odeslání",
      "status": "Stav"
    },
    "overview": {
      "EET": "EET",
      "amount": "Celkem",
      "amountSales": "Prodeje",
      "amountServices": "Služby",
      "date": "Datum",
      "eetDetails": "Detaily EET",
      "eetIsNotOk": "Pro vybrané období EET vykazuje chyby odeslání.",
      "eetIsOk": "Pro vybrané období je EET v pořádku.",
      "eetState": "EET",
      "entities": "Ekonomické entity",
      "entityName": "Název entity",
      "export": "Exportovat",
      "exportFileName": "zakazky.xlsx",
      "exportSheetName": "Zakázky",
      "heading": "Přehled zakázek",
      "isPagingExceeded": "Ve vybraném období je více než {max} zakázek. Součet nelze vypočítat. Prosím, zadejte kratší období.",
      "paidByCard": "Kartou",
      "paidByOther": "Jinak",
      "paidInCash": "Hotově",
      "paidInVoucher": "Vouchery",
      "payments": "Typy plateb",
      "price": "Cena za položky",
      "priceWithTip": "Celková částka i s dýškem",
      "state": {
        "failed": "Odeslání EET selhalo",
        "not_needed": "EET nebylo třeba odeslat",
        "sent": "EET bylo úspěšně odesláno"
      },
      "taxes": "Daně",
      "thisMonth": "tento měsíc",
      "thisWeek": "tento týden",
      "tip": "Dýško",
      "today": "dnes",
      "typeOfPayment": "Typ platby",
      "visits": "Zakázky",
      "vouchers": "Vouchery"
    },
    "pieces": "ks",
    "registration": {
      "failure": "Při registraci došlo k chybě",
      "heading": "Registrace",
      "save": "Uložit",
      "success": "Registrace proběhla úspěšně"
    },
    "reservation": {
      "createVisit": "Vytvořit zakázku",
      "customer": "Zákazník",
      "employee": "Zaměstnanec",
      "invalidNotification": "Není vyplněný telefon, prosím zrušte volbu zaslání SMS",
      "modalHeader": "Rezervace",
      "note": "Poznámka",
      "removeReservation": "Odstranit rezervaci",
      "saveReservation": "Uložit rezervaci",
      "selectCustomer": "Vyberte zákazníka",
      "selectEmployee": "Vyberte zaměstnance",
      "selectedEmployee": "Zaměstnanec",
      "updateEstimate": "Synchronizovat"
    },
    "revenues": {
      "createdAt": "Datum",
      "eetResponse": "Odezva EET serverů",
      "eetState": "Stav",
      "heading": "Příjmy pro které je stav EET: {state}",
      "resendEet": "Odeslat EET"
    },
    "schedule": {
      "employee": "Zaměstnanec",
      "modalHeader": "Směna",
      "removeSchedule": "Odstranit směnu",
      "saveSchedule": "Uložit směnu",
      "selectEmployee": "Vyberte zaměstnance"
    },
    "sell": {
      "action": "Akce",
      "count": "Kusů",
      "customer": "Zákazník",
      "date": "Datum",
      "employee": "Zeměstnanec",
      "item": "Položka",
      "itemType": "Typ",
      "select_item": "-- Vyber položku --",
      "sum_price": "Suma",
      "unit_price": "Jed. cena",
      "visit": "Zakázka"
    },
    "sent": "odesláno",
    "sidebar": {
      "admin": {
        "customers": "Zákazníci",
        "employees": "Zaměstnanci",
        "items": "Sklad",
        "notifications": "Upozornění",
        "preferences": "Firma",
        "supply": "Naskladnění",
        "users": "Uživatelé",
        "vouchers": "Vouchery"
      },
      "calendar": "Rezervace",
      "cashbook": "Pokladní kniha",
      "changelog": "Informace",
      "customers": "Zákazníci",
      "forceReload": "Znovu načíst aplikaci",
      "heading": {
        "admin": "Administrátor",
        "employee": "Zaměstnanec",
        "navigation": "Navigace",
        "profile": "Profil"
      },
      "itemDispenses": "Výdeje / Prodeje",
      "items": "Sklad",
      "logout": "Odhlásit",
      "overview": "Zakázky",
      "reminders": "Upozornění",
      "visits": "Otevřené zakázky"
    },
    "signup": {
      "header": "Registrace nového salonu"
    },
    "supply": {
      "create": "Naskladnit",
      "created_at": "Naskladněno",
      "heading": "Naskladnění",
      "item": "Položka skladu",
      "lastPurchasePrice": "Poslední nákupní cena",
      "purchasePrice": "Jednotková cena",
      "quantity": "Množství",
      "sum": "Suma",
      "vat": "DPH"
    },
    "test": "passsed",
    "title": {
      "branding": "beauty-pay.cz"
    },
    "updatedAt": "Upraveno",
    "validations": {
      "between": "Musí mít mezi {min} a {max} znaky",
      "confirmation": "{description} neodpovídá {on}",
      "email": "Neplatná e-mailová adresa",
      "fromDate": "FROM date must be less than TO date",
      "greaterThan": "TO date must be be greater then FROM date",
      "inclusion": "Musí být vybráno",
      "invalid": "Neplatný formát",
      "lessThan": "FROM date must be less than TO date",
      "notANumber": "Musí být číslo",
      "present": "Prosím vyplňte",
      "toDate": "TO date has to be greater then FROM date"
    },
    "vat": {
      "no": "(bez DPH)",
      "yes": "(s DPH)"
    },
    "versionChecker": {
      "changelog": "change logu",
      "reload": "obnovit",
      "textChangelog": "Seznam změn je k dispozici v",
      "textReload": "Aplikace byla aktualizována. Prosím, dokončete svou práci a poté klikněte na"
    },
    "visit": {
      "all_open": "Všechny otevřené zakázky",
      "all_visits": "Všechny otevřené zakázky",
      "amountSales": "Prodeje",
      "amountServices": "Služby",
      "auto_complete": "Hledej...",
      "back_to_customer": "Zpátky na zákazníka",
      "back_to_visit": "Zpátky do zakázky",
      "checkout": "K pokladně",
      "close": "Uzavřít",
      "close_warning": "Zakázka bude uzavřena. Již nebude možno přidávat, odebírat ani měnit položky.",
      "copyDiscountsPopup": "Zkopírovat hodnotu z prvního řádku do všech řádků.",
      "count": "Počet",
      "createdAt": "Datum",
      "creatingVisitFailed": "Nepodařilo se vytvořit zakázku, zkuste to později",
      "creatingVisitSucceeded": "Zakázka vytvořena",
      "customer": "Zákazník",
      "deleteVisit": "Smazat prázdnou zakázku",
      "element": "Položka",
      "employee": "Zaměstnanec",
      "employeeShareSales": "Podíl zaměstnance prodej",
      "employeeShareServices": "Podíl zaměstnance služby",
      "employee_margin": "Podíl zaměstnance",
      "enablePaymentByCard": "platba kartou",
      "hasUnsavedSells": "Neuložili jste položku zakázky. Klikněte na zelené plus, nebo položku smažte.",
      "isInvoicePrintingActive": "Tisknout účtenku",
      "itemName": "Položka",
      "new": {
        "confirm": "Přidat",
        "selectCustomer": "-- Vyber --",
        "selectEmployee": "-- Vyber --"
      },
      "noLastVisit": "---",
      "note": "Poznámka k zakázce",
      "noteUpdated": "Poznámka uložena",
      "number": "Hodnota",
      "paidByCard": "Zaplaceno kartou",
      "paid_by_other": "Zaplacené jinak",
      "paid_in_cash": "Zaplaceno v hotovosti",
      "paid_in_voucher": "Zaplaceno vouchery",
      "paymentSection": "Platba",
      "price": "Celková cena",
      "priceSection": "Částka",
      "print": {
        "bkp": "bkp",
        "count": "Množství",
        "eetAmount": "částka na EET",
        "facebook": "Facebook",
        "fik": "fik",
        "footer": "vytvořeno pomocí systému www.beauty-pay.cz",
        "goToVisits": "Zpátky na seznam zakázek",
        "identificationNumber": "IČO",
        "isNotVatPayer": "není plátcem DPH",
        "isVatPayer": "plátce DPH",
        "name": "Položka",
        "or": "nebo",
        "phone": "tel.",
        "premisesid": "Číslo provozovny",
        "price": "Celkem",
        "print": "Vytisknout",
        "registerid": "Číslo pokladny",
        "revenueId": "Číslo účtenky",
        "sum": "Cena celkem:",
        "typeOfEet": "Režim tržby: běžný",
        "unitPrice": "JC",
        "vatid": "DIČ"
      },
      "receivedCash": "Přijato v hotovosti",
      "reopen": "Znovu otevřít",
      "return_amount": "K vrácení",
      "selectAnItem": "Vyberte položku",
      "sellPrice": "Jednotková cena",
      "sellSum": "Cena celkem",
      "sellsSection": "Položky zakázky",
      "sum_price_is": "Celková částka",
      "sum_with_tip_is": "Celková částka i s dýškem",
      "tip": "Dýško",
      "updatedAt": "Datum",
      "visit_of": "Návštěva",
      "voucherClaimInvalid": "Tuto částku nelze uplatnit",
      "voucherClaimsSection": "Vouchery - uplatnění",
      "vouchersSection": "Vouchery - nákup"
    },
    "visits": {
      "new": "Nová zakázka "
    },
    "voucher": {
      "add": "Přidat voucher",
      "barcode": "Čárový kód",
      "duplicateVoucher": "Duplicate voucher",
      "entity": "Ekonomická entita",
      "name": "Název",
      "new": "Nový voucher",
      "note": "Poznámka",
      "sellingPrice": "Prodejní cena",
      "valid": {
        "12months": "12 měsíců",
        "3months": "3 měsíce",
        "6months": "6 měsíců"
      },
      "validUntil": "Platnost do",
      "vat": "DPH",
      "voucherDetails": "Detaily voucheru",
      "voucherNotAvailable": "Invalid voucher"
    },
    "voucherClaim": {
      "claims": "Uplatnění voucheru"
    },
    "will_send": "k odeslání",
    "x-customer": {
      "birth": "Datum narození",
      "mail": "E-mail",
      "phone": "Telefon",
      "save": "Uložit"
    },
    "x-note": {
      "noNote": "[poznámka zákazníka]",
      "note": "poznámka zákazníka",
      "save": "Uložit"
    },
    "x-notification": {
      "error": "Chyba",
      "ready": "Připravena",
      "scheduled": "Naplánována",
      "sent": "Odeslána"
    }
  }], ["en", {
    "Add": "Add",
    "Cancel": "Cancel",
    "Close": "Close",
    "Confirmation": "Confirmation",
    "DataGrid": {
      "fulltextLabel": "Filtration",
      "fulltextPlaceholder": "Searched phrase"
    },
    "Delete": "Delete",
    "Deleted": "Deleted",
    "Edit": "Edit",
    "History": "History",
    "ID": "ID",
    "Identification": "Username",
    "Login": "Log in",
    "My secret password": "My secret password",
    "New": "New",
    "Next": "Next",
    "No data": "No data",
    "Password": "Password",
    "Previous": "Previous",
    "Save": "Impose",
    "Saved": "Saved",
    "Saving": "Saving ...",
    "Select manually": "Select manually",
    "Submit": "Send",
    "T": "T",
    "Today": "Today",
    "admin": {
      "companies": {
        "address": "Address",
        "companyHeading": "My company",
        "entitiesHeading": "Economic entities",
        "expiryDate": "Paid by",
        "facebook": "Facebook",
        "isVatPayer": "The payer of VAT",
        "messageLength": "Message length",
        "phone": "Telephone",
        "save": "Impose",
        "website": "Web"
      },
      "customer": {
        "visitsSum": "Total"
      },
      "customers": {
        "all": "All customers",
        "import": "Import"
      },
      "employee": {
        "back": "List of employees",
        "dispensesAndSells": "Disbursements / Sales",
        "edit": "Editing",
        "heading": "Employee {name}",
        "items": {
          "count": "Sold pieces",
          "itemName": "Item",
          "itemSellingPrice": "Unit price",
          "sum": "Sum",
          "sumOfPurchasePrices": "Amount in the last purchase price",
          "sumOfPurchasePricesVAT": "Amount in the last purchase price with VAT",
          "sumOfSellingPricesVAT": "Amount in the selling price with VAT"
        },
        "itemsList": "Warehouse items",
        "month": "Moon",
        "notActive": "Inactive",
        "percent": "%",
        "save": "Impose",
        "savedError": "An error occurred while saving",
        "savedOk": "Employee saved",
        "visits": "Orders",
        "visitsEmployeeShareTitle": "{shareOfSales}*{amountSales} + {shareOfServices}*{amountServices}"
      },
      "import": {
        "customer": {
          "welcomeMessage": "Here you can bulk import customers"
        },
        "dataFormat": "Format dat",
        "done": "{Count} rows imported",
        "error": "Imports, I guess",
        "heading": {
          "customer": "Customers",
          "item": "Warehouse items",
          "page": "Import"
        },
        "item": {
          "csv": "Input CSV",
          "example": "Inoa 3.14,5671237890,12.34 Kč\nChildren's cut, 01001010101,501,90\nShampoo, 55555555555,69 CZK",
          "exampleHeading": "Example CSV",
          "parse": "Parse",
          "save": "Impose"
        },
        "message": "Input CSV format",
        "model": {
          "customer": "Full name, First name, Last name,,,,,,,,,,, Date of birth, Gender,,,,,,,,,, Note,, [Email], Email,,,,,, [Mobile], Mobile phone",
          "item": ",,,,,,,,,,, Item name, EAN (barcode), Sales price, Discharge price, Minimum stock",
          "supply": ", Quantity,, Purchase price (excl. VAT),, Tax in (%),,,,,, EAN (barcode)"
        },
        "reset": "Start again",
        "sampleData": {
          "item": "Shampoo, 9004715248468, 120, 100, 10",
          "supply": "9004715248468, 10, 120, 21"
        },
        "supply": "Here you can perform bulk storage"
      },
      "item": {
        "back": "List of warehouse items",
        "edit": "Editing",
        "heading": "Warehouse item {name}",
        "mailtoBody": "Thanks for purchasing {name}",
        "mailtoLabel": "Send email to all buyers",
        "mailtoSubject": "Newsletter pro {name}",
        "savedError": "Item failed to save",
        "savedOk": "Item saved",
        "sells": "Sales",
        "supplies": "Storage"
      },
      "items": {
        "activeAndInactive": "Both",
        "barcode": "Barcode",
        "entityName": "Economic entity",
        "export": "Export",
        "import": "Import",
        "isActiveLabel": "Show only",
        "isNotService": "Sale",
        "isService": "Service",
        "isServiceAndNotService": "Both",
        "name": "Name",
        "onlyActive": "Active",
        "onlyInactive": "Inactive",
        "perPage": "Show items per page",
        "purchasePrice": "Last purchase price",
        "sellingPrice": "Selling price",
        "stock": "In stock",
        "stockExportFileName": "sklad.xlsx",
        "stockExportSheetName": "Warehouse items",
        "sum": "The amount of the fund",
        "sumExplained": "Only filtered items that do not have an unlimited quantity and have a last purchase price> 0 are counted",
        "sumFiltered": "Filtered"
      },
      "supply": {
        "dragAndDrop": "You can perform a bulk import by dragging the DOD file.",
        "dropToUpload": "Release here.",
        "import": "Import",
        "importFormat": "barcode, quantity, purchase price (excluding VAT), purchase VAT",
        "importInfo": "The supported format is CSV (comma separated values). The individual columns are:",
        "lastSupplies": "Last {count} stockings",
        "new": "Manual storage",
        "newSupply": "New storage",
        "uploadProgress": "File loaded, continue."
      },
      "users": {
        "email": "E-mail"
      },
      "visit": {
        "createdAt": "date",
        "customer": "Customer",
        "delete": "Delete",
        "employee": "Employee",
        "header": "The order",
        "note": "Note",
        "reopenFailed": "Opening failed",
        "reopenWarning": "Attention! Messages already sent to EET will not be sent again. All other changes you make will affect the entire system (employee commissions, sales overview, cash book, stock status).",
        "reopened": "Job reopened",
        "revenue": {
          "amount": "Total",
          "amountSales": "Sales",
          "amountServices": "Services",
          "entityName": "Economic entity"
        },
        "revenues": "Revenues",
        "saveFailed": "Save failed",
        "saved": "Saved",
        "sells": "Items",
        "view": "Display",
        "visitDetails": "Details"
      },
      "wages": {
        "companyDebt": "It remains",
        "incomes": "Revenues",
        "left": "It remains",
        "new": "New payout",
        "shareSales": "Sales shares",
        "shareServices": "Shares in services",
        "sums": "Grand totals",
        "wages": "Payouts"
      }
    },
    "api": {
      "200": "Saved",
      "500": "An error occurred: {message}"
    },
    "app-menu": {
      "top": {
        "logoAlt": "beauty-pay.cz logo"
      }
    },
    "application": {
      "title": "safe"
    },
    "calendar": {
      "allEmployees": "All employees",
      "clipboard": "Box",
      "copySchedules": "Copy shifts",
      "day": "On",
      "editMode": "Edit shifts",
      "end": "The end",
      "from": "from",
      "header": "Reservation",
      "modeReservation": "born",
      "modeSchedule": "year",
      "pasteSchedules": "Insert shifts",
      "reservationActions": "Action",
      "start": "Beginning",
      "to": "do",
      "weeklySchedules": "Week shown"
    },
    "cashbook": {
      "add": "Add",
      "amount": "Amount",
      "createdAt": "date",
      "disclaimer": "Make a withdrawal from the cash book over a negative amount.",
      "heading": "Cashbook",
      "isVisit": "The order",
      "kind": "Type / Action",
      "note": "Note"
    },
    "createdAt": "Created",
    "currency": "CZK",
    "customer": {
      "action": "Action",
      "add": "Add a customer",
      "allCustomers": "List of customers",
      "all_visits": "List of visits",
      "birth": "Birthdate",
      "birthdayIn": "Birthday",
      "export": "Export contacts",
      "firstName": "Name",
      "fullName": "Name",
      "gender": "Sex",
      "genders": {
        "female": "A woman",
        "male": "Man"
      },
      "isApproved": "Approved",
      "lastName": "Surname",
      "lastVisitDate": "Last visit",
      "mail": "E-mail",
      "note": "Note",
      "phone": "Telephone",
      "saveErrro": "Error saving",
      "saveOk": "Saved",
      "visits_length": "Visits"
    },
    "customers": {
      "all": "All customers",
      "filterBy": {
        "label": "Filter",
        "placeholder": "Jan Novák ..."
      },
      "newCustomer": "New customer",
      "newsletter": "Newsletter"
    },
    "dateSelect": {
      "date": "the",
      "enabled": "Provide",
      "month": "Moon",
      "year": "year"
    },
    "employee": {
      "action": "Action",
      "add": "Add",
      "all_employees": "All employees",
      "all_visits": "All orders",
      "color": "Color",
      "firstName": "Name",
      "isActive": "Active",
      "lastName": "Surname",
      "photoUrl": "Photo"
    },
    "employees": {
      "all": "Employees"
    },
    "failed": "failed",
    "female": "A woman",
    "form": {
      "save": "Impose"
    },
    "formFor": {
      "amount": "Amount",
      "cashbook-entry": {
        "amount": "Amount",
        "note": "Note"
      },
      "company": {
        "address": "Address",
        "email": "E-mail",
        "facebook": "Facebook",
        "invoice-header": "Receipt header",
        "invoice-logo": "Logo at účtence",
        "is-invoice-printing-active": "Print receipts",
        "name": "Name of the company",
        "notificationPlaceholdersHelp": "Messages longer than 170 characters will be divided into more SMS. Placeholders: %'{company_name}' - company name, %'{date}' - visit date, %'{time}' visit time,%'{company_phone}' - company phone",
        "notificationWarning": "Before switching on, please contact beauty-pay.cz support first",
        "phone": "Telephone",
        "reservation-notification-template": "SMS message template",
        "save": "Impose",
        "website": "Web",
        "will-notify-reservations": "SMS notifications of customers about upcoming reservations"
      },
      "customer": {
        "agree": "I agree with",
        "birth": "Birthdate",
        "first-name": "Name",
        "gender": "Sex",
        "is-approved": "Approved",
        "last-name": "Surname",
        "mail": "E-mail",
        "note": "Note",
        "phone": "Telephone",
        "terms-and-conditions": "Conditions for processing personal data"
      },
      "employee": {
        "color": "Color",
        "first-name": "Name",
        "is-active": "Active",
        "last-name": "Surname",
        "photo-url": "Photo URL",
        "share-of-sales": "Share of sales",
        "share-of-sales-percent": "Share of sales",
        "share-of-services": "Share of services",
        "share-of-services-percent": "Share of services"
      },
      "entity": {
        "business-register": "Entry in the Commercial Register",
        "headquarters": "Headquarters",
        "identification-number": "IČO",
        "invoice-header": "Heel on the receipt",
        "is-vat-payer": "The payer of VAT",
        "name": "Entity name",
        "premisesid": "Establishment number",
        "registerid": "Cash register number",
        "send-eet": "Send EET - sales only",
        "vatid": "VAT number"
      },
      "identification": "E-mail",
      "item": {
        "barcode": "Barcode",
        "entity": "Economic entity",
        "is-active": "Active",
        "is-service": "Service",
        "name": "Name",
        "selling-price": "Selling price",
        "unlimited": "Unlimited",
        "warning-threshold": "At least in stock"
      },
      "note": "Note",
      "password": "Password",
      "reservation": {
        "end": "do",
        "note": "Note",
        "start": "from",
        "will-notify": "Notify the customer in advance by SMS message"
      },
      "schedule": {
        "note": "Note"
      },
      "sell": {
        "count": "Pieces",
        "discount": "Discount",
        "price": "Price"
      },
      "supply": {
        "item": {
          "selling-price": "Selling price"
        },
        "itemName": "Warehouse item",
        "purchase-price": "Purchase price (excluding VAT)",
        "quantity": "Amount",
        "vat": "VAT purchase"
      },
      "user": {
        "password": "Password",
        "passwordConfirmation": "password confirmation"
      },
      "username": "E-mail"
    },
    "inPercent": "{name} v (%)",
    "intervalPicker": {
      "export": "Download",
      "from": "from",
      "invalidRange": "The \"from\" date cannot be after the \"to\" date.",
      "minusMonth": "-1 month",
      "plusMonth": "+1 month",
      "thisDay": "Today",
      "thisMonth": "This month",
      "thisWeek": "This week",
      "to": "do"
    },
    "item": {
      "action": "Action",
      "actualCount": "In stock",
      "barcode": "Barcode",
      "bought": "In stock",
      "edit": "Edit",
      "isActive": "Active",
      "isService": "Service",
      "margin": "Margins",
      "name": "Name",
      "new": "New warehouse item",
      "newItemEntity": "Economic entity",
      "purchasePrice": "Purchase",
      "sellingPrice": "Selling price",
      "sold": "Sold",
      "stock": "In stock",
      "unlimited": "Unlimited amount",
      "unlimited_or_stock": "In stock",
      "warningThreshold": "At least in stock"
    },
    "itemDispenses": {
      "historyOfItemDispenses": "Expenditure history",
      "new": "New issue",
      "sells": "Sales history"
    },
    "items": {
      "active": "Active",
      "add": "Add",
      "all": "Warehouse",
      "filterBy": {
        "count": "pieces",
        "label": "Filtration",
        "placeholder": "Shampoo ...",
        "showing": "Viewed"
      },
      "inactive": "Inactive",
      "item": {
        "lastDispenses": "Last 10 issues",
        "newItemDispense": "New edition"
      },
      "newBarcode": "123456789",
      "newName": "New item",
      "newSellingPrice": "100",
      "newStock": "10",
      "newWarningThreshold": "5"
    },
    "john@doe": {
      "cz": "jan@novak.cz"
    },
    "loadMore": "More...",
    "loading": "... loading ...",
    "login": {
      "branding": "beauty-pay.cz",
      "header": "Login",
      "invalidCredentials": "Invalid login details",
      "logoAlt": "beauty-pay.cz logo",
      "signup": "Register",
      "submit": "Log in"
    },
    "male": "Man",
    "min": "min",
    "minutes": "{count, plural, =1 {{count} minuta} =2 {{count} minuty} other {{count} minut}}",
    "models": {
      "cashbookEntry": {
        "amount": "Amount",
        "isVisit": "The order",
        "kind": "Type",
        "note": "Note",
        "touchedAt": "date"
      },
      "company": {
        "_modelName": "{count, plural, =1 {Firma} =2 {Firmy} other {Firem}}",
        "address": "Address",
        "email": "E-mail",
        "expiryDate": "Expiration date",
        "facebook": "Facebook URL",
        "facebookTitle": "Name on Facebook",
        "instagram": "Instagram URL",
        "instagramTitle": "Name on Instagram",
        "invoiceHeader": "Receipt header",
        "invoiceLogo": "Logo",
        "isInvoicePrintingActive": "Print receipt",
        "name": "Name",
        "note": "Note",
        "phone": "Telephone",
        "phoneLandline": "Landline",
        "reservationNotificationTemplate": "Alert template",
        "website": "Web",
        "willNotifyReservations": "Send booking notifications"
      },
      "customer": {
        "_modelName": "Customer",
        "birth": "Birthdate",
        "firstName": "Name",
        "fullName": "Name",
        "gender": "Sex",
        "genders": {
          "female": "A woman",
          "male": "Man"
        },
        "isApproved": "approved",
        "lastName": "Surname",
        "lastVisitDate": "Last visit",
        "mail": "E-mail",
        "name": "Name",
        "note": "Note",
        "phone": "Telephone number",
        "termsConfirmation": "I agree with the conditions of personal data processing",
        "visitsCount": "Visits"
      },
      "employee": {
        "_modelName": "{count, plural, =1 {Employee} =2 {Employees} other {Employees}}",
        "companyDebt": "Company debt",
        "firstName": "Name",
        "lastName": "Surname",
        "photoUrl": "Photo",
        "shareOfSales": "Share of sales",
        "shareOfServices": "Share of services",
        "sumOfShareServices": "Amount of commissions from services",
        "sumOfWages": "Amount of payments"
      },
      "entity": {
        "_modelName": "{count, plural, =1 {Economic Entity} =2 {Economic Entities} other {Economic Entities}}",
        "businessRegister": "Entry in the Commercial Register",
        "headquarters": "Headquarters",
        "identificationNumber": "IČO",
        "invoiceHeader": "Heel on the receipt",
        "isVatPayer": "The payer of VAT",
        "name": "Entity name",
        "premisesid": "Establishment number",
        "registerid": "Cash register number",
        "sendEet": "Send EET - sales only",
        "vatid": "VAT number"
      },
      "invoice": {
        "_modelName": "{count, plural, =1 {Invoice} =2 {Invoices} other {Invoices}}",
        "amount": "Amount",
        "end": "Do",
        "isPaid": "Paid",
        "note": "Note",
        "sentSms": "SMS",
        "start": "From"
      },
      "item": {
        "_modelName": "{count, plural, =1 {Warehouse Item} =2 {Warehouse Items} other {Warehouse Items}}",
        "barcode": "Barcode",
        "bought": "In stock",
        "dispensingPrice": "Delivery price",
        "isActive": "Active",
        "isService": "Service",
        "lastPurchasePrice": "Last purchase price",
        "margin": "Margins",
        "name": "Name",
        "sellingPrice": "Selling price",
        "sold": "Sold",
        "stock": "In stock",
        "stockUpdatedAt": "Last quantity change",
        "unlimited": "Unlimited amount",
        "warningThreshold": "At least in stock"
      },
      "itemDispense": {
        "_modelName": "{count, plural, =1 {Disbursement} =2 {Disbursements} other {Disbursements}}",
        "count": "Pieces",
        "sumPrice": "Price",
        "unitPrice": "Price / Fr."
      },
      "notification": {
        "_modelName": "Notification",
        "message": "Text",
        "notification": "{count, plural, =1 {Notifications} =2 {Notifications} other {Notifications}}",
        "phone": "Telephone",
        "sendAt": "Sent",
        "sentSms": "News",
        "status": "State",
        "statusMessage": "Status"
      },
      "payment": {
        "_modelName": "{count, plural, =1 {Payment} =2 {Payments} other {Payment}}",
        "amount": "Amount",
        "end": "Do",
        "note": "Note",
        "start": "From"
      },
      "reservation": {
        "_modelName": "{count, plural, other {Rezervace}}",
        "end": "The end",
        "note": "Note",
        "start": "Beginning",
        "willNotify": "Alert"
      },
      "sell": {
        "_modelName": "{count, plural, =1 {Order} =2 {Orders} other {Orders}}",
        "count": "Pieces",
        "discount": "Discount",
        "item": "entry",
        "price": "Jed. the price",
        "sum": "Sum"
      },
      "signup": {
        "_modelName": "{count, plural, =1 {Registration} =2 {Registration} other {Registration}}",
        "email": "E-mail",
        "employeeCount": "Number of employees / colleagues",
        "firstName": "Name",
        "identificationNumber": "IČO",
        "lastName": "Surname",
        "phone": "Telephone"
      },
      "supply": {
        "_modelName": "{count, plural, =1 {Storage} =2 {Storage} other {Storage}}",
        "createdAt": "Created",
        "margin": "Margins",
        "purchasePrice": "Purchase price (excluding VAT)",
        "quantity": "Amount",
        "vat": "VAT purchase"
      },
      "user": {
        "_modelName": "{count, plural, =1 {User} =2 {Users} other {Users}}",
        "currentSignInAt": "Current login",
        "email": "E-mail",
        "lastSignInAt": "Last login",
        "password": "Password",
        "passwordConfirmation": "password confirmation",
        "signInCount": "Number of logins"
      },
      "vat": {
        "0": "Zero (0%)",
        "10": "Second reduced (10%)",
        "21": "Basic (21%)",
        "_modelName": "VAT"
      },
      "visit": {
        "_modelName": "{count, plural, =1 {Visits} =2 {Visits} other {Visits}}",
        "amountSales": "Sales",
        "amountServices": "service",
        "completed": "Closed",
        "employeeShare": "➗ employees",
        "employeeShareSales": "➗ from sale",
        "employeeShareServices": "➗ for services",
        "note": "Note",
        "paidByCard": "card",
        "paidByOther": "Other",
        "paidInCash": "Cash",
        "paidInVoucher": "Voucher claim",
        "price": "Price",
        "priceWithTip": "In total, even with a nozzle",
        "tip": "tip"
      },
      "voucher": {
        "barcode": "Barcode",
        "name": "Name",
        "note": "Note",
        "remainingPrice": "Remaining Price",
        "sellingPrice": "Selling Price",
        "validUntil": "Valid Until"
      },
      "wage": {
        "_modelName": "{count, plural, =1 {Payout} =2 {Payouts} other {Payout}}",
        "amount": "Amount",
        "note": "Note"
      }
    },
    "noNewNotifications": "No new notifications",
    "not_needed": "there was no need",
    "notification": {
      "message": "Message",
      "phone": "Telephone",
      "sendAt": "Time of sending",
      "status": "State"
    },
    "overview": {
      "EET": "EAT",
      "amount": "Total",
      "amountSales": "Sales",
      "amountServices": "Services",
      "date": "date",
      "eetDetails": "EET details",
      "eetIsNotOk": "EET reports transmission errors for the selected period.",
      "eetIsOk": "The EET is OK for the selected period.",
      "eetState": "EAT",
      "entities": "Economic entities",
      "entityName": "Entity name",
      "export": "Export",
      "exportFileName": "zakazky.xlsx",
      "exportSheetName": "Orders",
      "heading": "Overview of orders",
      "isPagingExceeded": "There are more than {max} orders in the selected period. The sum cannot be calculated. Please enter a shorter period.",
      "paidByCard": "Card",
      "paidByOther": "Other",
      "paidInCash": "Cash",
      "paidInVoucher": "Voucher claim",
      "payments": "Types of payments",
      "price": "Price per item",
      "priceWithTip": "The total amount even with a nozzle",
      "state": {
        "failed": "EET send failed",
        "not_needed": "EET did not need to be sent",
        "sent": "EET was sent successfully"
      },
      "taxes": "Taxes",
      "thisMonth": "this month",
      "thisWeek": "this week",
      "tip": "Dysko",
      "today": "today",
      "typeOfPayment": "Type of payment",
      "visits": "Orders"
    },
    "pieces": "pcs",
    "registration": {
      "failure": "An error occurred while registering",
      "heading": "Registration",
      "save": "Impose",
      "success": "Registration was successful"
    },
    "reservation": {
      "createVisit": "Create an order",
      "customer": "Customer",
      "employee": "Employee",
      "invalidNotification": "The phone is not filled in, please deselect the option to send an SMS",
      "modalHeader": "Reservation",
      "note": "Note",
      "removeReservation": "Delete reservation",
      "saveReservation": "Save the reservation",
      "selectCustomer": "Select a customer",
      "selectEmployee": "Select employees",
      "selectedEmployee": "Employee",
      "updateEstimate": "Synchronize"
    },
    "revenues": {
      "createdAt": "date",
      "eetResponse": "Response of EET servers",
      "eetState": "State",
      "heading": "Revenue for which the state is EET: {state}",
      "resendEet": "Submit EET"
    },
    "schedule": {
      "employee": "Employee",
      "modalHeader": "Shift",
      "removeSchedule": "Delete shift",
      "saveSchedule": "Save shift",
      "selectEmployee": "Select employees"
    },
    "sell": {
      "action": "Action",
      "count": "Pieces",
      "customer": "Customer",
      "date": "date",
      "employee": "Employee",
      "item": "Item",
      "itemType": "Type",
      "select_item": "- Select an item -",
      "sum_price": "Sum",
      "unit_price": "Jed. the price",
      "visit": "The order"
    },
    "sent": "sent",
    "sidebar": {
      "admin": {
        "customers": "Customers",
        "employees": "Employees",
        "items": "Warehouse",
        "notifications": "Notice",
        "preferences": "Company",
        "supply": "Storage",
        "users": "Users",
        "vouchers": "Vouchers"
      },
      "calendar": "Reservation",
      "cashbook": "Cashbook",
      "changelog": "Information",
      "customers": "Customers",
      "forceReload": "Reload the application",
      "heading": {
        "admin": "Administrator",
        "employee": "Employee",
        "navigation": "Navigation",
        "profile": "Profile"
      },
      "itemDispenses": "Disbursements / Sales",
      "items": "Warehouse",
      "logout": "Log out",
      "overview": "Orders",
      "reminders": "Notice",
      "visits": "Open orders"
    },
    "signup": {
      "header": "Registration of a new salon"
    },
    "supply": {
      "create": "Store",
      "created_at": "In stock",
      "heading": "Storage",
      "item": "Warehouse item",
      "lastPurchasePrice": "Last purchase price",
      "purchasePrice": "Unit price",
      "quantity": "Amount",
      "sum": "Sum",
      "vat": "VAT"
    },
    "test": "passsed",
    "title": {
      "branding": "beauty-pay.cz"
    },
    "validations": {
      "between": "Must have between {min} and {max} characters",
      "email": "Invalid email address",
      "greaterThan": "TO date must be be greater then FROM date",
      "inclusion": "Must be selected",
      "invalid": "Invalid format",
      "lessThan": "FROM date must be less than TO date",
      "notANumber": "Must be a number",
      "present": "Please fill"
    },
    "vat": {
      "no": "(without VAT)",
      "yes": "(with VAT)"
    },
    "versionChecker": {
      "changelog": "change window",
      "reload": "restore",
      "textChangelog": "A list of changes is available in",
      "textReload": "The application has been updated. Please complete your work and then click on"
    },
    "visit": {
      "all_open": "All open orders",
      "all_visits": "All open orders",
      "amountSales": "Sales",
      "amountServices": "Services",
      "auto_complete": "Search ...",
      "back_to_customer": "Back to the customer",
      "back_to_visit": "Back to the order",
      "checkout": "To the cash desk",
      "close": "Conclude",
      "close_warning": "The order will be closed. It will no longer be possible to add, remove or change items.",
      "copyDiscountsPopup": "Copy the value from the first row to all rows.",
      "count": "Number",
      "createdAt": "date",
      "creatingVisitFailed": "Failed to create order, please try again later",
      "creatingVisitSucceeded": "Order created",
      "customer": "Customer",
      "deleteVisit": "Delete an empty order",
      "element": "Item",
      "employee": "Employee",
      "employeeShareSales": "Share employee sales",
      "employeeShareServices": "Service employee share",
      "employee_margin": "Employee share",
      "enablePaymentByCard": "credit card payment",
      "hasUnsavedSells": "You have not saved the order item. Click the green plus or delete the item.",
      "isInvoicePrintingActive": "Print receipt",
      "itemName": "Item",
      "new": {
        "confirm": "Add",
        "selectCustomer": "- Select -",
        "selectEmployee": "- Select -"
      },
      "noLastVisit": "---",
      "note": "Order note",
      "noteUpdated": "Note saved",
      "number": "Value",
      "paidByCard": "Paid by card",
      "paid_by_other": "Paid by other",
      "paid_in_cash": "Paid in cash",
      "paid_in_voucher": "Paid in voucher",
      "paymentSection": "Payment",
      "price": "total price",
      "priceSection": "Amount",
      "print": {
        "bkp": "bkp",
        "count": "Amount",
        "eetAmount": "amount per EET",
        "facebook": "Facebook",
        "fik": "got",
        "footer": "created using the system www.beauty-pay.cz",
        "goToVisits": "Back to the list of orders",
        "identificationNumber": "IČO",
        "isNotVatPayer": "is not a VAT payer",
        "isVatPayer": "the payer of VAT",
        "name": "Item",
        "or": "or",
        "phone": "Phone.",
        "premisesid": "Establishment number",
        "price": "Total",
        "print": "Print",
        "registerid": "Cash register number",
        "revenueId": "Receipt number",
        "sum": "Total price:",
        "typeOfEet": "Sales mode: normal",
        "unitPrice": "JC",
        "vatid": "VAT number"
      },
      "receivedCash": "Accepted in cash",
      "reopen": "Open again",
      "return_amount": "To be returned",
      "selectAnItem": "Select an item",
      "sellPrice": "Unit price",
      "sellSum": "Total price",
      "sum_price_is": "total amount",
      "sum_with_tip_is": "The total amount even with a nozzle",
      "tip": "Dysko",
      "updatedAt": "date",
      "visit_of": "Visit"
    },
    "visits": {
      "new": "New order"
    },
    "voucher": {
      "add": "Add Voucher",
      "barcode": "Barcode",
      "duplicateVoucher": "Duplicate voucher",
      "entity": "Entity",
      "name": "Name",
      "new": "New Voucher",
      "note": "Note",
      "sellingPrice": "Selling Price",
      "validUntil": "Valid Until",
      "vat": "Vat",
      "voucherDetails": "Details",
      "voucherNotAvailable": "Invalid voucher"
    },
    "voucherClaim": {
      "amount": "Amount",
      "claims": "Claims",
      "createdAt": "Date",
      "customer": "Customer",
      "employee": "Employee"
    },
    "will_send": "to send",
    "x-customer": {
      "birth": "Birthdate",
      "mail": "E-mail",
      "phone": "Telephone",
      "save": "Impose"
    },
    "x-note": {
      "noNote": "[customer note]",
      "note": "customer note",
      "save": "Impose"
    },
    "x-notification": {
      "error": "I guess",
      "ready": "Ready",
      "scheduled": "Scheduled",
      "sent": "Sent"
    }
  }]];
});